$('.btn-menu').click(function(e){
	var target = $(this).attr('data-target');
	$(target).toggleClass('topo__main-navigation--active');
	$(this).toggleClass('active');
	$('.bg-menu').fadeIn(400);
});

$('.bg-menu').click(function(){
	$('.topo__main-navigation').removeClass('topo__main-navigation--active');
	$('.btn-menu').removeClass('active');
	$(this).fadeOut(400);
});